


































































import './styles/MemberCard.scss'
import './styles/MemberCardAdaptive.scss'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { PopupActions } from '@store/Popup'
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload'
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { IMemberRole, IProject } from '@store/modules/project/Interfaces';
import { IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces'
import { ProjectActions, ProjectMutations } from '@store/modules/project/Types';
import { IProjectTeamPopupsState } from '@store/modules/project-team/modules/popups/Interfaces'
import { formatDate } from "@utility/formatDate";
import { ITask } from "@store/modules/project-tasks/interfaces/Interfaces";
import { IJuristicEntity } from "@store/modules/about-project/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSProjectTeamPopups = namespace('storeProjectTeam/storeProjectTeamPopups');
const NSProjectTasks = namespace('storeProjectTasks');
const NSAboutProject = namespace('storeAboutProject');

@Component({
    name: 'MemberCard',

    components: {
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class MemberCard extends Vue {
    @Prop({}) member!: IMemberInfo;
    @Prop({}) part!: string;
    @Prop({ default: false }) isOnTop!: boolean;
    @Prop({ default: false }) showColumnKey!: boolean;

    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Getter('memberRoles') memberRoles!: IMemberRole[];
    @NSProject.Action(ProjectActions.A_READ_OBJECTS) readObjects!: (payload) => Promise<void>
    @NSProject.Mutation(ProjectMutations.M_SET_NEW_PROJECT_TEAM) setNewTeam!: (payload) => Promise<void>
    @NSProjectTeam.Action(ProjectTeamActions.A_SET_VIEWED_MEMBER) setViewedMember!: (member: IMemberInfo) => void;
    @NSProjectTeamPopups.Getter('rolesPopupToggler') rolesPopupToggler!: boolean;
    @NSProjectTeamPopups.Action(PopupActions.A_TOGGLE_POPUP) togglePopup!: (payload: ITogglePopupPayload<IProjectTeamPopupsState>) => void;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSProjectTasks.Getter('unfinishedTasks') unfinishedTasks!: ITask[];
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];

    get attachmentLabel(): string {
        if (this.part === 'engineer') return 'Инженерная часть';
        return 'Портал';
    }

    get isMemberNew(): boolean {
        if (this.projectData.newTeam) {
            return this.projectData.newTeam.includes(this.member.uuid);
        }
        return false;
    }

    get activationDatePerson(): string | number {
        let earlierActivationDate: string | number = '-';
        this.juristicEntities.forEach((entity: IJuristicEntity) => {
            entity.responsiblePersons.forEach((resPerson) => {
                if (resPerson.id === this.member.individualId) {
                    earlierActivationDate = new Date().getTime();
                    if (!resPerson.activationDate) {
                        earlierActivationDate = '-';
                    } else {
                        let date = new Date(resPerson.activationDate);
                        if (date.getTime() <= earlierActivationDate) {
                            earlierActivationDate = formatDate({date: date, format: 'dd.mm.yyyy'});
                        }
                    }
                }
            })
        });
        return earlierActivationDate;
    }

    get countUnfinishedTasks() {
        let countTasks = 0;
        countTasks = this.unfinishedTasks.filter((task) => {
            return task.executor?.uuid === this.member.uuid
                || task.author?.uuid === this.member.uuid;

        }).length;
        return countTasks === 0 ? '-' : countTasks;
    }

    goToMember() {
        this.checkNewMembers();
        this.setViewedMember(this.member);
        this.changeRoute();
    }

    checkNewMembers() {
        if (this.projectData.newTeam && this.projectData.newTeam.includes(this.member.uuid)) {
            this.readObjects({
                appId: this.projectId,
                ids: [this.member.uuid],
                type: 'member',
            }).then(() => {
                if (this.projectData && this.projectData.newTeam) {
                    const newTeamIds = this.projectData.newTeam.filter(taskId => taskId !== this.member.uuid);
                    this.setNewTeam(newTeamIds);
                }
            })
        }
    }

    changeRoute() {
        this.$router.push({
            name: 'team-member',
            params: {memberUuid: this.member.uuid}
        });
    }

    chooseRole() {
        this.setViewedMember(this.member);
        this.toggleRolesPopup();
    }

    toggleRolesPopup() {
        this.togglePopup({
            popupToggler: 'rolesPopupToggler',
            state: !this.rolesPopupToggler,
        });
    }
}
